import React from "react"
import PropTypes from "prop-types"
import { keyframes } from "@emotion/react"

import Columns from "./columns"
import Column from "./column"
import Heading from "./heading"
import Box from "./box"
import Stack from "./stack"

const FeedbackHeaderProgress = ({ correct, total, variant }) => {
  const scale = keyframes({
    from: {
      transform: "scaleX(0)",
    },
    to: {
      transform: "scaleX(1)",
    },
  })

  const renderAnswers = (type, limit) => {
    var elements = []
    for (let i = 0; i < limit; i++) {
      elements.push(
        <Column key={`answer_${type}_${i}_${limit}`}>
          <Box
            sx={{
              height: 3,
              bg: "muted",
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                bg: type,
                width: "100%",
                transform: "scaleX(0)",
                animationFillMode: "forwards",
                transformOrigin: "left",
                animationName: scale.toString(),
                animationDuration: "150ms",
                animationTimingFunction: "linear",
                animationDelay: `${i * 150}ms`,
              }}
            />
          </Box>
        </Column>
      )
    }
    return elements
  }

  return (
    <Stack space={3}>
      <Heading as="h1" level={5}>
        {variant === "solution" ? (
          <>
            {correct === 0
              ? "Leider hast du keine der Lösungen gefunden. Hier findest du die Auflösung:"
              : correct === total
              ? "Du hast alle Lösungen gefunden!"
              : `Du hast ${correct} von ${total} Lösungen gefunden!`}
          </>
        ) : (
          <>
            {correct === 0
              ? "Leider war keine deiner Antworten richtig. Hier findest du die Auflösung:"
              : correct === total
              ? "Du hast alle Fragen richtig beantwortet!"
              : `Du hast ${correct} von ${total} Fragen richtig beantwortet!`}
          </>
        )}
      </Heading>
      {correct > 0 && (
        <Columns space="px">
          {correct === total ? (
            renderAnswers("positive", correct)
          ) : (
            <>
              {renderAnswers("positive", correct)}
              {renderAnswers("muted", total - correct)}
            </>
          )}
        </Columns>
      )}
    </Stack>
  )
}

FeedbackHeaderProgress.propTypes = {
  statistics: PropTypes.object,
  variant: PropTypes.oneOf(["question", "solution"]),
}

FeedbackHeaderProgress.defaultProps = {
  variant: "question",
}

export default FeedbackHeaderProgress
