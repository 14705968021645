import React from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"
import { useThemeUI } from "theme-ui"
import { RoughNotation } from "react-rough-notation"
import { useNotationGroup } from "./notation-group"
import { answerStatus } from "../store/courses"

const NotationCorrectionSolution = (props) => {
  const { children, id } = props
  const { state } = useNotationGroup()
  const status = state?.[id]?.status || answerStatus.VALID_UNCHECKED
  const correction = state?.[id]?.answer?.correction
  const solution = state?.[id]?.solution

  const context = useThemeUI()
  const { theme } = context

  const solutionStyles = {
    [answerStatus.VALID_CHECKED]: {
      show: true,
      type: "strike-through",
      color: theme.colors["positive"],
    },
    [answerStatus.VALID_UNCHECKED]: {
      show: false,
    },
    [answerStatus.INVALID]: {
      show: true,
      type: "strike-through",
      color: theme.colors["negative"],
    },
    [answerStatus.MISSED]: {
      show: true,
      type: "strike-through",
      color: theme.colors["caution"],
    },
  }

  const answerStyles = {
    [answerStatus.VALID_CHECKED]: {
      show: true,
      type: "circle",
      color: theme.colors["positive"],
    },
    [answerStatus.VALID_UNCHECKED]: {
      show: false,
    },
    [answerStatus.INVALID]: {
      show: true,
      type: "circle",
      color: theme.colors["negative"],
    },
    [answerStatus.MISSED]: {
      show: true,
      type: "circle",
      color: theme.colors["caution"],
    },
  }

  const solutionStyle = solutionStyles[status]
  const answerStyle = answerStyles[status]

  // no correction needed and not tried to correct
  if (!correction && status !== answerStatus.MISSED) {
    return children
  }

  // no correction needed but answer given
  if (!solution && answerStatus.INVALID) {
    return (
      <>
        {children}{" "}
        <RoughNotation
          show={true}
          type="crossed-off"
          color={theme.colors["negative"]}
        >
          {correction}
        </RoughNotation>
      </>
    )
  }

  // correction needed but not answered
  if (status === answerStatus.MISSED) {
    return (
      <>
        <RoughNotation {...solutionStyle}>{children}</RoughNotation>{" "}
        <RoughNotation {...answerStyle}>{solution}</RoughNotation>
      </>
    )
  }

  // correction needed but answered wrong
  if (status === answerStatus.INVALID) {
    return (
      <>
        <RoughNotation {...solutionStyle}>{children}</RoughNotation>{" "}
        <RoughNotation
          show={true}
          type="crossed-off"
          color={theme.colors["negative"]}
        >
          {correction}
        </RoughNotation>{" "}
        <RoughNotation {...answerStyle}>{solution}</RoughNotation>
      </>
    )
  }

  // correct
  return (
    <>
      <RoughNotation {...solutionStyle}>{children}</RoughNotation>{" "}
      <RoughNotation {...answerStyle}>{correction}</RoughNotation>
    </>
  )
}

NotationCorrectionSolution.propTypes = {
  id: PropTypes.string.isRequired,
}

export default NotationCorrectionSolution
